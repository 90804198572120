@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#program-services {
  padding: 4rem 4rem;
}

#program-services div h2 {
  text-align: center;
  padding-bottom: 4rem;
}

.program-section img {
  width: 80%;
  border-radius: 18px;
}

.program-section p {
  color: var(--darkGray);
  padding-bottom: 1rem;
}

@media only screen and (max-width: 640px) {
  #program-services {
    padding: 2rem !important;
  }
  .program-section {
    text-align: center;
  }
  .program-section p {
    padding-top: 2rem;
  }
  .btn-program {
    width: 80%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .btn-program {
    width: 80%;
  }
}
