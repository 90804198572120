:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#tasks {
  background-color: var(--transparentGray);
  padding-top: 4rem;
  padding-bottom: 8rem;
}

.row {
  --bs-gutter-x: 0px !important;
}

.title {
  padding-bottom: 4rem;
  font-size: 24px;
  text-align: center;
  color: var(--gray);
  font-weight: bold;
}

.title span {
  color: var(--skyBlue);
}

.box {
  padding: 2rem 4rem 2rem 4rem;
  background-color: white;
  text-align: center;
  border-radius: 18px;
  box-shadow: 6px 18px 12px -13px rgba(0, 0, 0, 0.53);
  -webkit-box-shadow: 6px 18px 12px -13px rgba(0, 0, 0, 0.53);
}

.box img {
  width: 4rem;
  height: 4rem;
}

.box h4 {
  color: var(--gray);
  font-size: 18px;
  padding-top: 1rem;
}

@media only screen and (max-width: 640px) {
  #tasks {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .box {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  #tasks {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .box {
    padding: 2rem 2rem 2rem 2rem;
  }
}
