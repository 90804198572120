:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;
  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#clinic-convention {
  padding: 4rem;
}

.convention-title {
  padding-bottom: 4rem;
}

.clinic-convention-item div img {
  border-radius: 12px;
  width: 24rem;
  height: 24rem;
}

.clinic-convention-item div p {
  color: var(--darkGray);
}

.clinic-convention-item div li {
  color: var(--darkGray);
}

@media only screen and (max-width: 640px) {
  .clinic-convention-item div img {
    border-radius: 12px;
    width: 100%;
    height: 16rem;
    object-fit: cover;
  }
  .clinic-convention-item div p {
    padding-top: 1rem;
    text-align: center;
  }
  .clinic-convention-item div li {
    padding-top: 1rem;
  }
}
