@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#team {
  background-color: white;
  padding: 5rem 5rem;
}

.team-title h2 {
  color: var(--skyBlue);
  text-align: center;
  font-size: 28px;
  font-weight: 600 !important;
  padding-bottom: 5rem;
}

.team-title {
  text-align: center;
}

.team-title button {
  margin-top: 4rem;
}

@media only screen and (max-width: 640px) {
  .team-title h2 {
    padding-bottom: 3rem;
  }
  .btn-team {
    width: 100%;
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .team-title button {
    width: 80%;
  }
}
