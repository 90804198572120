@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --black: #000;
  --orange: #ff9505;
}

footer {
  background-color: var(--transparentGray);
  font-family: var(--mainFont);
  padding-top: 70px;
  padding-bottom: 70px;
}

hr {
  margin-right: 70px;
  margin-left: 70px;
}

.footer-title {
  font-weight: 500 !important;
  color: var(--darkGray);
  font-size: 24px;
}

.footer-text {
  color: var(--darkGray);
  font-size: 16px;
}

.copyright p {
  color: var(--darkGray);
}

.logo-box img {
  width: 12rem;
  margin-bottom: 2rem;
}

.icon {
  color: var(--darkGray);
}

.icon:hover {
  color: var(--skyBlue);
}

@media only screen and (max-width: 640px) {
  .logo-box {
    text-align: center;
  }
  .footer-box {
    text-align: center;
    padding-top: 1rem;
  }
  .copyright {
    text-align: center;
  }
  .social {
    display: inline;
    width: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .btn-banner-mob {
    width: 50%;
  }
  .logo-box img {
    width: 10rem;
    margin-bottom: 2rem;
  }
  .footer-title {
    font-size: 20px;
  }
  .footer-text {
    color: var(--darkGray);
    font-size: 14px;
  }
  .social {
    display: inline;
    width: 20%;
  }
}
