@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#call-to-action {
  padding: 4rem;
  text-align: center;
  background-color: var(--skyBlue);
}

#call-to-action h2 {
  color: var(--white);
  padding-bottom: 1rem;
}

#call-to-action p {
  color: var(--white);
  padding-bottom: 1rem;
}

#call-to-action button {
  width: 20%;
  padding: 0.8rem;
}

@media only screen and (max-width: 640px) {
  #call-to-action button {
    width: 80%;
    padding: 0.8rem;
  }
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  #call-to-action button {
    width: 40%;
  }
}
