@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --lightBlue: #d0e5f2;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#item-list-container {
  background-color: var(--transparentGray);
  padding: 5rem;
}

#item-list-container h2 {
  text-align: center;
  color: var(--gray);
}

#item-list-container h2 span {
  color: var(--skyBlue);
}

.item-filter {
  padding-top: 3rem;
}

.item-filter div p {
  color: var(--darkGray);
}

.item-filter div button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.btn-active {
  border-radius: 12px;
  background-color: var(--lightBlue);
  border: none;
  padding: 0.4rem 2.5rem 0.4rem 2.5rem;
  color: var(--blue);
}

.btn-inactive {
  border: 1px solid var(--gray);
  color: var(--gray);
  border-radius: 12px;
  padding: 0.4rem 2.5rem 0.4rem 2.5rem;
  background-color: transparent;
}
