@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.accordion-button:not(.collapsed) {
  color: black;
}
.accordion-button:focus {
  border-color: var(--gray) !important;
  box-shadow: 0 0 0 0.25rem rgb(185, 192, 208, 0.2);
}
.accordion-item {
  border: none;
  margin-bottom: 1rem;
}

.accordion-header button {
  background-color: var(--transparentGray) !important;
  font-weight: 600;
}

.chevron {
  text-align: end;
}
