@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.banner {
  position: relative;
  height: 40rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-mob {
  display: none;
}

.banner div {
  position: absolute;
  z-index: 99;
}

.banner-text {
  margin-left: 10rem;
}

.banner-text h2 {
  color: var(--white);
  font-size: 36px;
}

.banner-text p {
  color: var(--white);
}

.play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
}

@media only screen and (max-width: 640px) {
  .banner {
    display: none;
  }
  .banner-mob {
    display: block;
    position: relative;
    height: 44rem;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banner-text {
    margin-left: 0rem;
    padding: 2rem;
    text-align: center;
    padding-top: 22rem;
  }
  .banner-text h2 {
    padding-bottom: 1rem;
  }
  .btn-banner-mob {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .btn-banner-mob {
    width: 50%;
  }
}
