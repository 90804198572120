@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

a {
  text-decoration: none !important;
}

.card-container {
  box-shadow: 1px 14px 28px -15px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 1px 14px 28px -15px rgba(0, 0, 0, 0.74);
  text-align: center;
  border-radius: 12px;
  background-color: var(--white);
  margin: 1rem;
}

.card-container div img {
  padding-top: 1rem;
  height: 120px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card-container div h5 {
  padding-top: 1rem;
  color: var(--blue);
  font-weight: 600;
}

.card-container div h6 {
  color: var(--skyBlue);
}
