@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

body {
  font-family: var(--mainFont);
}

h2 {
  color: var(--skyBlue);
  font-size: 30px;
  font-weight: 700 !important;
  font-family: var(--mainFont);
}

.main-btn {
  background-color: var(--skyBlue);
  border: none;
  border-radius: 30px;
  color: white;
  font-family: var(--mainFont);
  font-weight: 500 !important;
  width: 30%;
  padding: 10px 10px;
}

.second-btn {
  background-color: var(--orange);
  border: none;
  border-radius: 30px;
  color: white;
  font-family: var(--mainFont);
  font-weight: 500 !important;
  width: 40%;
  padding: 3px 3px;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}

/* @media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
} */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--skyBlue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}
