@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#testimonial-services {
  padding: 4rem;
  text-align: center;
}

#testimonial-services div h2 {
  padding-bottom: 4rem;
}

.item-testimonial div img {
  border-radius: 12px;
  width: 26rem;
  height: 18rem;
  object-fit: cover;
}

.item-testimonial div h3 {
  padding-top: 1rem;
  text-align: left;
  padding-left: 5rem;
  font-weight: 600;
  font-size: 24px;
}

.item-testimonial div p {
  padding-top: 0.2rem;
  padding-bottom: 1rem;
  text-align: left;
  padding-left: 5rem;
  color: var(--darkGray);
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .item-testimonial div img {
    width: 16rem !important;
    height: 10rem !important;
  }
  .item-testimonial div h3 {
    padding-left: 1rem !important;
    font-size: 22px;
  }
  .item-testimonial div p {
    padding-left: 1rem !important;
    font-size: 14px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
  .item-testimonial div img {
    width: 16rem !important;
    height: 10rem !important;
  }
}
