:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;
  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.navbar {
  background-color: white !important;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a {
  display: flex;
  align-items: center;
  color: var(--darkGray);
  white-space: nowrap;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 600;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar li:hover > a {
  color: var(--skyBlue);
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  left: -20px;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  border-bottom: var(--skyBlue) 4px solid;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #0194e6;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

.navbar-brand {
  margin: 0px 0px 0px 40px;
}

.navbar-brand img {
  width: 10rem;
}

.active {
  color: var(--skyBlue) !important;
}

.nav-link {
  font-weight: bold;
  color: var(--gray);
  font-size: 18px;
}

.nav-link:hover {
  color: var(--skyBlue) !important;
}

.drop-span {
  margin-right: 0.3rem;
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .navbar a {
    font-size: 16px;
    margin: 0rem 0.1rem 0rem 0.1rem;
  }
  .nav-item {
    margin: 0rem 1.5rem 0rem 1.5rem !important;
  }
  /* .navbar-collapse {
    height: 100vh !important;
  } */
}
