@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#patients {
  padding: 6rem;
}

.pain-title h4 {
  color: var(--darkGray);
  text-align: center;
  font-weight: 600;
}

.pain-title span {
  color: var(--skyBlue);
}

.pain-desc img {
  width: 1.8rem;
  height: 1.8rem;
}

.pain-desc h5 {
  color: var(--darkGray);
  font-family: var(--mainFont);
  font-weight: 600;
  padding-left: 1rem;
}

.pain-desc p {
  color: var(--darkGray);
  font-family: var(--mainFont);
  padding-left: 1rem;
}

#testimonial-services {
  padding: 4rem;
  text-align: center;
}

#testimonial-services div h2 {
  padding-bottom: 4rem;
}

.item-testimonial div img {
  border-radius: 12px;
  width: 26rem;
  height: 18rem;
  object-fit: cover;
}

.item-testimonial div h3 {
  padding-top: 1rem;
  text-align: left;
  padding-left: 5rem;
  font-weight: 600;
  font-size: 24px;
}

.item-testimonial div p {
  padding-top: 0.2rem;
  padding-bottom: 1rem;
  text-align: left;
  padding-left: 5rem;
  color: var(--darkGray);
}

@media only screen and (max-width: 640px) {
  #patients {
    padding: 2rem !important;
  }
  .pain-title h4 {
    padding-bottom: 2rem;
  }
  .icon-patient {
    width: 20%;
    text-align: center;
    padding-bottom: 1rem;
  }
  #testimonial-services {
    padding: 0rem;
    text-align: center;
  }
  #testimonial-services div h2 {
    padding-top: 2rem;
  }
  .item-testimonial div img {
    width: 20rem;
  }
  .item-testimonial div h3 {
    padding-top: 1rem;
    text-align: center;
    padding-left: 0rem;
  }
  .item-testimonial div p {
    text-align: center;
    padding-left: 0rem;
  }
}
