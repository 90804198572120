@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

body {
  font-family: var(--mainFont);
}

#specialties {
  background-color: var(--skyBlue);
  padding: 5rem 5rem;
}
.specialty-title {
  margin: 3rem 6rem;
}

.specialty-title h2 {
  color: white;
  font-size: 28px;
  font-weight: 600 !important;
  text-align: center;
}

.specialty-img {
  text-align: center;
}

.specialty-img img {
  height: 10rem;
  width: 8rem;
  padding-bottom: 2rem;
}

.specialty-img h4 {
  color: var(--white);
  font-size: 24px;
  padding-bottom: 5rem;
}
