@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#conventions {
  background-color: var(--transparentGray);
  padding: 4rem 4rem;
}

.conventions-item {
  text-align: center;
}

.conventions-item h4 {
  padding-top: 1rem;
  color: var(--blue);
  font-weight: 600;
  max-width: 14.5rem;
  margin: auto;
}

.conventions-item p {
  color: var(--darkGray);
  padding-top: 2rem;
}

.conventions-item img {
  width: 12rem;
  height: 9rem;
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .conventions-item img {
    width: 8rem;
    height: 6rem;
  }
  .conventions-item p {
    font-size: 16px;
  }
}
