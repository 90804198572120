@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#call {
  padding: 4rem 4rem;
  background-color: var(--transparentGray);
}

.call-title {
  padding-bottom: 3rem;
}

.call-title h2 {
  text-align: center;
}

.call-item {
  text-align: center;
}

.call-item h2 {
  color: var(--darkGray);
  font-size: 24px;
}

.call-item img {
  width: 3rem;
  padding-bottom: 1rem;
}

.call-item p {
  color: var(--darkGray);
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  #call {
    padding: 3rem 3rem;
  }
}
