@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#testimonials {
  padding: 4rem;
}

#testimonials .splide {
  padding-bottom: 3rem;
}

#testimonials div {
  text-align: center;
}

.testimonials-title {
  padding-bottom: 4rem;
}

.splide__arrow {
  background: var(--skyBlue) !important;
  opacity: 1;
}

.splide__arrow:disabled {
  opacity: 1;
}

.splide__slide img {
  height: 10rem;
  width: 10rem;
  border-radius: 100%;
  object-fit: cover;
}

.splide__arrow svg {
  fill: var(--white) !important;
}

#testimonials div div h4 {
  font-weight: 600;
  font-size: 18px;
}

#testimonials div div p {
  font-weight: 500;
  font-size: 16px;
}

.splide__pagination__page {
  background: var(--white) !important;
  border: var(--orange) solid 2px !important;
  width: 1rem !important;
  height: 1rem !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.splide__pagination__page.is-active {
  background: var(--orange) !important;
  transform: scale(1) !important;
}

.testimonial-name h4 {
  padding-bottom: 0.5rem;
}

.testimonial-name p {
  padding-bottom: 0rem;
}

.stars {
  padding-bottom: 1rem;
}

.stars div {
  padding-bottom: 0.5rem !important;
}

.testimonial-link a {
  color: var(--skyBlue);
}

@media only screen and (max-width: 640px) {
  #testimonials {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .stars div {
    width: 10%;
  }
  .testimonial-name h4 {
    padding-top: 1rem;
  }
}
