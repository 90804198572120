:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#patients {
  padding: 6rem;
}

.pain-title h4 {
  color: var(--darkGray);
  text-align: center;
  font-weight: 600;
  font-size: 32px;
}

.pain-title span {
  color: var(--skyBlue);
}

.pain-desc img {
  width: 1.8rem;
  height: 1.8rem;
}

.pain-desc h5 {
  color: var(--darkGray);
  font-family: var(--mainFont);
  font-weight: 600;
  padding-left: 1rem;
}

.pain-desc p {
  color: var(--darkGray);
  font-family: var(--mainFont);
  padding-left: 1rem;
}

#province {
  padding: 4rem;
}

.item-desc {
  padding-top: 2rem;
}

.province-title {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.province-img img {
  height: 20rem;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.province-step {
  padding: 2rem;
}

.province-step h3 {
  color: var(--skyBlue);
  font-weight: 700;
  font-size: 22px;
}

.province-step p {
  padding-top: 0.5rem;
  color: var(--darkGray);
}

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .item-desc {
    padding-top: 3rem !important;
  }
}
