@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#care-about {
  padding: 4rem;
}

#care-about div h2 {
  padding-bottom: 3rem;
}

.carousel-doctors {
  margin: auto;
  border-radius: 20px;
  width: 80%;
  margin-bottom: 2rem;
}

.care-about {
  height: 550px;
  padding-bottom: 3rem;
}

.care-about img {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.care-text {
  color: var(--darkGray);
  text-align: center;
  margin: auto;
  width: 60%;
}

@media only screen and (max-width: 640px) {
  #care-about {
    padding: 1rem;
  }
  #care-about div h2 {
    max-width: none !important;
  }
  .care-text {
    width: 80%;
    padding-bottom: 1rem;
  }
}
