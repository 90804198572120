@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#slider-doc-specialty {
  padding: 4.2rem;
}

.slider-doc-title {
  text-align: center;
  padding-bottom: 3rem;
}

.card-doc-img {
  height: 20rem;
}

.card-doc-specialty {
  box-shadow: 1px 14px 28px -15px rgba(0, 0, 0, 0.74);
  -webkit-box-shadow: 1px 14px 28px -15px rgba(0, 0, 0, 0.74);
  text-align: center;
  border-radius: 12px;
  background-color: var(--white);
  margin: 1rem;
}

.card-doc-text h4 {
  padding-top: 1.2rem;
  padding-left: 12px;
  color: var(--blue);
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}

.card-doc-text h3 {
  color: var(--black);
  padding-left: 12px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.card-doc-text p {
  color: var(--darkGray);
  padding-bottom: 2rem;
  padding-left: 12px;
  font-size: 16px;
  text-align: left;
}

.doc-text {
  padding-left: 2rem;
}

.doc-text li {
  color: var(--darkGray);
  list-style-image: url(../../../img/icons/checked.svg);
}

.main-btn {
  margin-top: 1rem;
}

.splide__pagination {
  bottom: -30px;
}

@media only screen and (max-width: 640px) {
  #slider-doc-specialty {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .slider-doc-title {
    padding-bottom: 2rem;
  }
  .doc-text {
    padding-left: 0rem;
    margin-top: 3.5rem;
  }
  .btn-center {
    text-align: center;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
  #slider-doc-specialty {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
