@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#trust-about {
  background-color: var(--transparentGray);
  padding: 6rem;
}

.title-trust {
  text-align: center;
  padding-bottom: 4rem;
}

.item-trust {
  text-align: center;
  margin: auto;
}

.item-trust h4 {
  color: var(--blue);
  font-weight: 600;
  font-size: 22px;
  padding-bottom: 2rem;
}

.item-trust p {
  color: var(--darkGray);
  font-size: 16px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.item-trust img {
  height: 8rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 640px) {
  #trust-about {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .item-trust p {
    padding-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  #trust-about {
    padding: 3rem;
  }
  .item-trust {
    margin: 0px;
  }
}
