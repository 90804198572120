@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#soat-about {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 10rem;
  padding-right: 10rem;
  text-align: center;
}

#soat-about div {
  padding-bottom: 2rem;
}

.soat-title h2 {
  max-width: 46rem;
  margin-left: auto;
  margin-right: auto;
}

.soat-text p {
  max-width: 54rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--darkGray);
}

@media only screen and (max-width: 640px) {
  #soat-about {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }
  .splide-img-soat {
    width: 10rem !important;
  }
}
