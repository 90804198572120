@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.card-rounded {
  text-align: center;
  padding: 0rem 1rem 0rem 1rem;
}

.card-rounded img {
  border-radius: 100%;
  width: 12rem;
  height: 12rem;
  object-fit: cover;
}

.card-rounded h5 {
  padding-top: 1rem;
  color: var(--darkGray);
  font-weight: 600;
}

.card-rounded p {
  color: var(--darkGray);
  font-size: 16px;
}

/* PHONE */

@media only screen and (max-width: 640px) {
  .btn-mob {
    width: 100% !important;
  }
  .card-rounded {
    margin-top: 2rem;
  }
}

/* TABLET */

@media only screen and (min-width: 641px) and (max-width: 768px) {
  .btn-mob {
    width: 100% !important;
  }
  .card-rounded img {
    border-radius: 100%;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }

  .card-rounded h5 {
    font-size: 22px;
  }

  .card-rounded p {
    color: var(--darkGray);
    font-size: 16px;
  }
}
