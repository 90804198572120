@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#target {
  padding-top: 100px;
}

.joint-section img {
  height: 20rem;
  width: 20rem;
}

.target-title {
  max-width: 350px;
  text-align: center;
  padding-bottom: 80px;
}

.target-text {
  font-size: 18px;
  color: var(--darkGray);
  font-family: var(--mainFont);
  margin-bottom: 60px;
}

.target-text span {
  color: var(--orange);
  font-style: italic;
  font-weight: 500 !important;
}

@media only screen and (max-width: 640px) {
  .target-title {
    max-width: max-content;
    text-align: center;
    padding-bottom: 80px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .joint-section {
    text-align: center;
  }
  .target-text {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }
  .target-content {
    text-align: center;
    padding-bottom: 4rem;
  }
  .btn-target {
    width: 80%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .btn-target {
    width: 50%;
  }
  .target-text {
    font-size: 16px;
  }
}
