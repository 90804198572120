@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.card-doctor {
  text-align: center;
  background-color: var(--white);
  margin: 4rem;
  width: 17rem;
}

.card-doctor div img {
  height: 400px;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.card-doctor div h5 {
  padding-top: 1rem;
  color: var(--darkGray);
  font-weight: 600;
}

.card-doctor div p {
  color: var(--darkGray);
}

.second-btn {
  width: 60%;
  padding: 0.5rem;
}

.modal-doctor {
  width: 100vw;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: 0px;
}

.modal-content {
  border-radius: 0px;
}

.modal-img img {
  height: 100%;
  width: 16rem;
  object-fit: cover;
  border-radius: 12px;
}

.modal-desc {
  margin-left: 2rem;
}

.modal-desc p {
  color: var(--darkGray);
}

.modal-desc h4 {
  color: var(--darkGray);
  font-weight: 600;
  font-size: 18px;
}

.cod-doc {
  margin-left: 1rem;
}

a {
  color: var(--skyBlue);
}

.modal-desc h5 {
  font-size: 18px;
}

.icons {
  padding-top: 0.5rem;
}

.btn-close {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2332a7da%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat;
}

@media only screen and (max-width: 640px) {
  .modal-img {
    text-align: center;
    padding-bottom: 2rem;
  }
  .modal-desc {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0rem;
  }
  .icons {
    justify-content: center !important;
    text-align: center;
    padding-top: 2rem;
  }
  .social-modal {
    width: 20%;
  }
}
