@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

.carousel-indicators .active {
  background-color: var(--orange) !important;
  width: 10px !important;
  height: 10px !important;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
  border: 3px solid var(--orange);
}

.carousel-indicators [data-bs-target] {
  border-radius: 100%;
  width: 10px !important;
  height: 10px !important;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}

.carousel-indicators [data-bs-target]:not(.active) {
  background: transparent !important;
  border: 3px solid var(--orange);
}

.carousel-indicators {
  bottom: 20px;
}

.carousel-item img {
  height: 700px;
  object-fit: cover;
}
