@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#item-detail {
}

#item-detail-section {
  padding: 4rem;
}

.item-desc {
  padding-top: 2rem;
}

.item-detail-title {
  text-align: center;
  padding-bottom: 2rem;
}

.detail-img img {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  object-fit: cover;
}

.detail-desc1 {
  padding-left: 2rem;
}

.detail-desc2 {
  padding-right: 2rem;
}

#testimonial-services {
  padding: 4rem;
  text-align: center;
}

#testimonial-services div h2 {
  padding-bottom: 4rem;
}

.item-testimonial-detail {
  text-align: center;
}

.item-testimonial-detail div img {
  border-radius: 12px;
  width: 26rem;
  height: 18rem;
  object-fit: cover;
}

.item-testimonial-detail div h3 {
  padding-top: 1rem;
  font-weight: 600;
  font-size: 24px;
}

.item-testimonial-detail div p {
  padding-top: 0.2rem;
  padding-bottom: 1rem;
  color: var(--darkGray);
}

@media only screen and (max-width: 640px) {
  #item-detail-section {
    padding: 2rem;
  }
  .detail-desc1 {
    padding-top: 2rem;
    padding-left: 0rem;
    text-align: center;
  }

  .detail-desc2 {
    padding-top: 2rem;
    padding-right: 0rem;
    text-align: center;
  }

  .order1 {
    order: 1;
  }
  .order2 {
    order: 2;
  }

  #testimonial-services div h2 {
    padding-bottom: 2rem !important;
  }

  .item-testimonial-detail div {
    padding: 1rem;
  }

  .item-testimonial-detail div img {
    width: 100%;
  }
}
