@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

:root {
  /* Fonts */
  --mainFont: "Montserrat", sans-serif;

  /* Colors */
  --blue: #004772;
  --lightOrange: #ffc971;
  --skyBlue: #32a7da;
  --gray: #b9c0d0;
  --transparentGray: rgb(185, 192, 208, 0.2);
  --white: #ffffff;
  --darkGray: #9fa5b2;
  --black: #000;
  --orange: #ff9505;
}

#email-form {
  padding: 4rem;
}

.email-title {
  text-align: center;
  padding-bottom: 4rem;
}

.message-form input {
  width: 100%;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border: none;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.message-form input::placeholder {
  color: var(--darkGray);
}

.message-form input:focus-visible {
  outline: var(--skyBlue) 2px solid;
}

.message-form textarea {
  width: 100%;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  border: none;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  resize: none;
}

.message-form textarea::placeholder {
  color: var(--darkGray);
}

.message-form textarea:focus-visible {
  outline: var(--skyBlue) 2px solid;
}

.toast {
  position: fixed;
  top: 90%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: var(--skyBlue);
  color: var(--white);
  width: 40%;
  font-size: 18px;
  text-align: center;
}

.background-darken {
  background-color: var(--transparentGray);
  height: 100vh;
  width: 100vw;
}

@media only screen and (max-width: 640px) {
  #email-form {
    padding: 3rem 2rem 3rem 2rem;
  }
}
